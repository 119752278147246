body {
  background-color: rgba(211, 211, 211, 0.1);
}

#product-scroller {
  height: 80vh;
  overflow-y: auto;
  padding: 1%;
}

#help-btn {
  position: static;
}

#content {
  height: 90vh;
}
