#progress-skeleton-window {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background-color: white;
  display: none;
  border: #bbbbbb 1px solid;
}

#loader {
  width: 100%;
  height: 100%;
  padding: 15%;
}

#progress-skeleton-window > a {
  position: absolute;
  right: 0%;
  top: 0%;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: #ddd;
}

#skeleton-status {
  background-color: #ddd;
}

#skeleton-logo {
  background-color: #ddd;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 40px;
}

#progress-notice-window {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  padding: 20px;
  background-color: white;
  display: none;
  border: #bbbbbb 1px solid;
}

#progress-notice-window > a {
  position: absolute;
  right: 0%;
  top: 0%;
}

#error-notice-window {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  padding: 20px;
  background-color: white;
  display: none;
  border: #bbbbbb 1px solid;
}

#error-notice-window > a {
  position: absolute;
  right: 0%;
  top: 0%;
}
